<template lang="html">
  <div class="fixed inset-0 table w-full h-full bg-windowShadow">
    <div class="table-cell align-middle">
      <div class="mx-auto shadow p-6 bg-white rounded-xl" style="width: 550px">
        <span
          class="col-start-1 col-end-2 font-bold text-darkblue text-xl font-SourceSansPro"
        >
          {{ $t('users.change_password_title') }}
        </span>
        <el-form
          ref="changePassword"
          :label-position="`top`"
          :model="changePasswordForm"
          :rules="customRules"
          :size="`medium`"
        >
          <div v-if="isPasswordMine" class="flex flex-col mb-6 relative">
            <div class="flex items-center mt-3">
              <span
                class="text-annotationColor font-SourceSansPro text-sm font-semibold mr-2"
              >
                {{ $t('users.current_password') }}
              </span>
            </div>
            <el-input
              v-model="changePasswordForm.currentPassword"
              :class="{ 'input-error': isPasswordError }"
              show-password="show-password"
              type="text"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                v-if="isPasswordError"
                class="mt-1 text-notify text-xs top-full left-0 absolute"
              >
                {{ passwordErrorMessage }}
              </div>
            </transition>
          </div>
          <el-form-item
            class="password-change-user"
            :label="
              isPasswordMine
                ? $t('loginPass.changePass.newPass')
                : $t('loginPass.changePass.inputPass')
            "
            prop="pass"
          >
            <div class="flex flex-col w-full">
              <el-input
                v-model="changePasswordForm.pass"
                :class="{ 'input-error': isNewPasswordError }"
                show-password="show-password"
                type="text"
              ></el-input>
              <div
                v-if="isNewPasswordError"
                class="text-notify text-xs"
                style="line-height: normal"
              >
                {{ passwordErrorMessage }}
              </div>
            </div>
          </el-form-item>
          <el-form-item
            class="h-20 password-change-user"
            :label="$t('loginPass.changePass.repeatNewPass')"
            prop="checkPass"
          >
            <el-input
              v-model="changePasswordForm.checkPass"
              show-password="show-password"
              type="text"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="flex justify-between pb-5">
          <skif-button @click="changePass(changePasswordForm)"
            >{{ $t('users.change_password') }}
          </skif-button>
          <skif-button variant="normal" @click="$emit('close')"
            >{{ $t('cancel') }}
          </skif-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { loginApi } from '@/api'
import { useI18n } from '@/hooks/useI18n'
import { getCurrentInstance, ref, computed } from 'vue'
import { useStore } from '@/store/store'

const $t = useI18n()
const instance = getCurrentInstance()
const store = useStore()

const props = defineProps({
  isPasswordMine: Boolean
})

const strongPassword = computed(() => {
  return store.getters['login/me'].active_company.strong_password
})

const role = computed(() => {
  return store.getters['login/me'].role.key
})

const checkPassMessage = computed(() => {
  if (strongPassword.value && role.value === 'ADMIN') {
    return $t('loginPass.errors.lengthPass.strongPass.adminUser')
  }
  if (strongPassword.value && role.value !== 'ADMIN') {
    return $t('loginPass.errors.lengthPass.strongPass.regularUser')
  }
  return $t('loginPass.errors.lengthPass')
})

const changePassword = ref(null)
const errorMessages = ref({
  pass: $t('loginPass.errors.pass'),
  repeatPass: $t('loginPass.errors.newPass'),
  notMatchPass: $t('loginPass.errors.notMatchPass'),
  lengthPass: checkPassMessage.value
})

const emits = defineEmits(['close'])
const passwordErrorMessage = ref('')
const isPasswordError = ref(false)
const isNewPasswordError = ref(false)
const changePasswordForm = ref({
  currentPassword: '',
  pass: '',
  checkPass: ''
})

const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(errorMessages.value.pass))
  } else if (changePasswordForm.value.checkPass !== '') {
    changePassword.value.validateField('checkPass')
  }
  callback()
}

const validateRepeatPass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(errorMessages.value.repeatPass))
  } else if (value !== changePasswordForm.value.pass) {
    callback(new Error(errorMessages.value.notMatchPass))
  } else {
    callback()
  }
}

const customRules = ref({
  pass: [
    {
      min: 5,
      max: 25,
      message: errorMessages.value.lengthPass
    },
    { validator: validatePass, trigger: 'blur' }
  ],
  checkPass: [
    {
      min: 5,
      max: 25,
      message: errorMessages.value.lengthPass
    },
    { validator: validateRepeatPass, trigger: 'blur' }
  ]
})

const setResponseErrors = (error) => {
  passwordErrorMessage.value = error.message

  if (error.field === 'password') {
    isPasswordError.value = true
    isNewPasswordError.value = false
  } else if (error.field === 'newPassword') {
    isPasswordError.value = false
    isNewPasswordError.value = true
  }
}

const changePass = () => {
  changePassword.value.validate((valid) => {
    if (!valid) return false

    if (props.isPasswordMine) {
      const formData = {}
      formData.newPassword = changePasswordForm.value.pass

      if (changePasswordForm.value.currentPassword) {
        formData.password = changePasswordForm.value.currentPassword
      }
      loginApi.changeMyPassword(
        formData,
        () => emits('close'),
        (error) => {
          setResponseErrors(error.response.data)
        }
      )
    } else {
      loginApi.resetPassword(
        instance.proxy.$parent.user.id,
        {
          newPassword: changePasswordForm.value.pass
        },
        () => emits('close'),
        (error) => {
          setResponseErrors(error.response.data)
        }
      )
    }
  })
}
</script>
<style lang="scss">
.input-error {
  .el-form-item__content {
    flex-direction: column;
    .el-form-item__error {
      position: relative;
    }
  }
}
.password-change-user {
  margin-bottom: 35px;
}
</style>
